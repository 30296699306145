// src/hooks/useDeviceData.js
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import api from "../utils";
import { useBackdrop } from "../context/BackdropContext";
import useSnackbarAlert from "./useSnackbarAlert";
import { API_ENDPOINTS } from "../config/apiEndpoints";


const AEP_TO_FETCH_DEVICE_DATA = API_ENDPOINTS.FETCH_DEVICE_DATA_API;

const ERROR_FETCHING_DEVICE_DATA = "Error fetching device data. Please try again later.";
const CONSOLE_ERROR_FETCH_DEVICE = "Error fetching device data:";
const ERROR_ALERT_SEVERITY = "error";

const useFetchDeviceData = (deviceId) => {
    const [device, setDevice] = useState({});
    const accessToken = useSelector((state) => state.user.accessToken);
    const {showBackdrop, hideBackdrop} = useBackdrop();
    const {showSnackbar, SnackbarComponent} = useSnackbarAlert();

    const fetchDeviceData = async () => {
        if (!deviceId) return;
         showBackdrop();
        try {
            const encodedDeviceId = encodeURIComponent(deviceId);
            const FINAL_API = `${AEP_TO_FETCH_DEVICE_DATA}/${encodedDeviceId}`;
            const response = await api.get(FINAL_API, {
                headers: { Authorization: `Bearer ${accessToken}` },
            });
            if (response.status === 200) {
                const newDevice = response.data.data;
                // Only set device if it has changed
                if (JSON.stringify(newDevice) !== JSON.stringify(device)) {
                    setDevice(newDevice);
                }
            }
        } catch (err) {
            showSnackbar(ERROR_FETCHING_DEVICE_DATA, ERROR_ALERT_SEVERITY);
            console.error(CONSOLE_ERROR_FETCH_DEVICE, err);
        }
        finally {
            hideBackdrop();
        }
    };

    useEffect(() => {
        fetchDeviceData();
        // eslint-disable-next-line
    }, [deviceId, accessToken]);

    return { device, SnackbarComponent };
};

export default useFetchDeviceData;
