import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { TextField, Button, Typography, CircularProgress, Divider, Tabs, Tab, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useMessage, useEmailOTP, useTimer, useUserExist, useGoogleAuth, useMobileOTP } from '../../hooks';
import styles from './AuthForm.module.css';
import GoogleButton from 'react-google-button';
import { validateEmail, validateMobileNumber, validateOTP } from '../../utils';
import { ROUTES } from '../../config';
import { EmailInput, MobileNumberInput } from '../inputFeilds';


// Authentication Types
const AUTH_TYPES = {
  EMAIL: 'email',
  MOBILE: 'mobile',
}
// Form Types
const FORM_TYPES = {
  LOGIN: 'login',
  REGISTER: 'register',
};

//Text Constants
const EMAIL_OTP_SENT_MESSAGE = `OTP sent. Please check your email.`;
const MOBILE_OTP_SENT_MESSAGE = `OTP sent. Please check your mobile.`;
const EMAIL_NOT_REGISTERED_MESSAGE = 'Email not registered. Please register.';
const EMAIL_ALREADY_REGISTERED_MESSAGE = 'Email already registered. Please login.';
const MOBILE_NOT_REGISTERED_MESSAGE = 'Mobile not registered. Please register.';
const MOBILE_ALREADY_REGISTERED_MESSAGE = 'Mobile already registered. Please login.';
const UNEXPECTED_RESPONSE_MESSAGE = 'Unexpected response. Please try again.';
const UNEXPECTED_ERROR_MESSAGE = 'Unexpected error. Please try again.';
const ERROR_CHECKING_REGISTRATION_MESSAGE = 'Error checking registration status.';
const REGISTER_LINK_TEXT = 'Not registered? Register here.';
const LOGIN_LINK_TEXT = 'Already registered? Login here.';
const GOOGLE_LOGIN_LABEL = 'Sign in with Google';
const GOOGLE_SIGNUP_LABEL = 'Sign up with Google';

// Helper Text
const HELPER_TEXT = {
  SHORT_OTP_LENGTH: 'OTP should be of 6 digits'
}

// Constants for UI
const MESSAGE_TYPOGRAPHY_VARIANT = 'body2';
const AUTH_TAB_BOX_BOX_BORDER_COLOR = 'divider';
const AUTH_TAB_BOX_TABS_VARIANT = 'fullWidth';
const MOBILE_OR_OTP_INPUT_PATTERN = '[0-9]*';
const AUTH_TAB_BOX_BORDER_BOTTOM_VALUE = 1;
const LENGTH_OF_OTP = 6;
const TIME_FOR_OTP_RESEND = 120;
const CIRCULAR_PROGRESS_SIZE = 24;
const EMPTY = '';

// Authentication Types
const AUTH_TABS_LABELS = {
  EMAIL: 'Email',
  MOBILE: 'Mobile',
}

// Button Props
const SEND_OTP_BUTTON_TEXT = 'Send OTP';
const VERIFY_OTP_BUTTON_TEXT = 'Verify OTP';

const SEND_OTP_BUTTON_PROPS = {
  variant: 'contained',
  color: 'primary',
  type: 'submit',
};

const VERIFY_OTP_BUTTON_PROPS = {
  variant: 'contained',
  color: 'primary',
};

const GOOGLE_BUTTON_PROPS = {
  style: { width: '100%' },
};

const SWITCH_LINK_TYPOGRAPHY_PROPS = {
  variant: 'body2',
  align: 'center',
};

// TextField Component Props
const TEXTFIELD_PROPS = {
  fullWidth: true,
  required: true,
  margin: 'normal',
  variant: 'outlined',
};

const EMAIL_INPUT_PROPS = {
  ...TEXTFIELD_PROPS,
  label: 'Email',
  type: 'email',
  name: 'email',
};

const MOBILE_INPUT_PROPS = {
  ...TEXTFIELD_PROPS,
  label: 'Mobile Number',
  type: 'numeric',
  name: 'mobile',
};

const OTP_INPUT_PROPS = {
  ...TEXTFIELD_PROPS,
  label: 'OTP',
  type: 'numeric',
  name: 'otp',
};


const AuthForm = ({ formType }) => {
  // States
  const [email, setEmail] = useState(EMPTY);
  const [mobile, setMobile] = useState(EMPTY);
  const [otp, setOtp] = useState(EMPTY);
  const [isSending, setIsSending] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);
  const [isOtpSent, setIsOtpSent] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [selectedTab, setSelectedTab] = useState(AUTH_TYPES.EMAIL);


  // Hooks
  const navigate = useNavigate();
  const isEmailValid = useMemo(() => validateEmail(email), [email]);
  const isMobileValid = useMemo(() => validateMobileNumber(mobile), [mobile]);
  const isOtpValid = useMemo(() => validateOTP(otp), [otp]);
  const { message, setMessage } = useMessage();
  const { timer, startTimer, resetTimer } = useTimer();
  const { sendEmailOTP, verifyEmailOTP } = useEmailOTP({ email, otp, formType, setIsSending, setIsVerifying, setMessage, setIsOtpSent, navigate });
  const { sendMobileOTP, verifyMobileOTP } = useMobileOTP({ mobile, otp, formType, setIsSending, setIsVerifying, setMessage, setIsOtpSent, navigate });
  const { checkUserExist, isChecking: isCheckingUserExist } = useUserExist();
  const { handleGoogleLogin } = useGoogleAuth();

  // Callbacks
  const resetForm = useCallback(() => {
    setOtp(EMPTY);
    setEmail(EMPTY);
    setMobile(EMPTY);
    setIsOtpSent(false);
    setMessage(EMPTY);
    resetTimer();
  }, [resetTimer, setMessage]);

  const handleSendOTP = async (e) => {
    e.preventDefault();
    setMessage(EMPTY);
    setIsSending(true);

    try {
      const userExists = await checkUserExist({
        email: selectedTab === AUTH_TYPES.EMAIL ? email : null,
        mobile_number: selectedTab === AUTH_TYPES.MOBILE ? mobile : null,
      });
      if ((userExists === true && formType === FORM_TYPES.LOGIN) || (userExists === false && formType === FORM_TYPES.REGISTER)) {
        selectedTab === AUTH_TYPES.EMAIL ? await sendEmailOTP() : await sendMobileOTP();
      } else if (userExists === true && formType === FORM_TYPES.REGISTER) {
        setMessage(selectedTab === AUTH_TYPES.EMAIL ? EMAIL_ALREADY_REGISTERED_MESSAGE : MOBILE_ALREADY_REGISTERED_MESSAGE);
      } else if (userExists === false && formType === FORM_TYPES.LOGIN) {
        setMessage(selectedTab === AUTH_TYPES.EMAIL ? EMAIL_NOT_REGISTERED_MESSAGE : MOBILE_NOT_REGISTERED_MESSAGE);
      } else {
        setMessage(UNEXPECTED_RESPONSE_MESSAGE);
      }
    } catch (err) {
      setMessage(ERROR_CHECKING_REGISTRATION_MESSAGE);
    } finally {
      setIsSending(false);
    }
  };

  const handleVerifyOTP = useCallback(async (e) => {
    e.preventDefault();
    setIsVerifying(true);
    try {
      selectedTab === AUTH_TYPES.EMAIL ? await verifyEmailOTP() : await verifyMobileOTP();
    } catch (err) {
      setMessage(UNEXPECTED_ERROR_MESSAGE);
    }
    finally {
      setIsVerifying(false);
    }
  }, [verifyEmailOTP, verifyMobileOTP, selectedTab, setMessage]);

  // Effects
  useEffect(() => {
    resetForm();
  }, [formType, resetForm]);

  useEffect(() => {
    if (isOtpSent) startTimer(TIME_FOR_OTP_RESEND);
  }, [isOtpSent, startTimer]);

  useEffect(() => {
    if (timer === 0) {
      setIsOtpSent(false);
      setOtp(EMPTY);
      resetTimer();
    }
  }, [timer, resetTimer]);

  // eslint-disable-next-line react/jsx-no-comment-textnodes
  return (
    <>
      <form onSubmit={isOtpSent? handleVerifyOTP : handleSendOTP}>
        {/* <AuthTabBox selectedTab={selectedTab} setSelectedTab={setSelectedTab} resetForm={resetForm} /> */}
        {selectedTab === AUTH_TYPES.EMAIL && (
          <EmailInput {...EMAIL_INPUT_PROPS} email={email} setEmail={setEmail} isEmailValid={isEmailValid} />
        )}
        {selectedTab === AUTH_TYPES.MOBILE && (
          <MobileNumberInput {...MOBILE_INPUT_PROPS} mobile={mobile} setMobile={setMobile} isMobileValid={isMobileValid} />
        )}
        {!isOtpSent ? (
          <SendOtpButton isEmailValid={isEmailValid} isMobileValid={isMobileValid} isSending={isSending} isCheckingUserExist={isCheckingUserExist} />
        ) : (
          <Typography variant={MESSAGE_TYPOGRAPHY_VARIANT}>
            {selectedTab === AUTH_TYPES.EMAIL ? EMAIL_OTP_SENT_MESSAGE : MOBILE_OTP_SENT_MESSAGE} Resend in {Math.floor(timer / 60)}:{('0' + (timer % 60)).slice(-2)}
          </Typography>
        )}
        <OtpInput otp={otp} setOtp={setOtp} isOtpSent={isOtpSent} timer={timer} isOtpValid={isOtpValid} handleVerifyOTP={handleVerifyOTP} />
        <VerifyOtpButton isOtpSent={isOtpSent} isVerifying={isVerifying} handleVerifyOTP={handleVerifyOTP} />
        {message && (
          <Typography className={styles.errorMessage} variant={MESSAGE_TYPOGRAPHY_VARIANT}>
            {message}
          </Typography>
        )}
      </form>
      <Divider>or</Divider>
      <GoogleButton
        onClick={handleGoogleLogin}
        {...GOOGLE_BUTTON_PROPS}
        label={formType === FORM_TYPES.LOGIN ? GOOGLE_LOGIN_LABEL : GOOGLE_SIGNUP_LABEL}
      />
      <SwitchLink formType={formType} navigate={navigate} />
    </>
  );
};

// Form Component Functions

// eslint-disable-next-line no-unused-vars
const AuthTabBox = ({ selectedTab, setSelectedTab, resetForm }) => (
  <Box sx={{ borderBottom: AUTH_TAB_BOX_BORDER_BOTTOM_VALUE, borderColor: AUTH_TAB_BOX_BOX_BORDER_COLOR }} >
    <Tabs
      value={selectedTab}
      onChange={(_, newValue) => {
        setSelectedTab(newValue);
        resetForm();
      }}
      variant={AUTH_TAB_BOX_TABS_VARIANT}
    >
      <Tab label={AUTH_TABS_LABELS.EMAIL} value={AUTH_TYPES.EMAIL} />
      <Tab label={AUTH_TABS_LABELS.MOBILE} value={AUTH_TYPES.MOBILE} />
    </Tabs>
  </Box>
);






const OtpInput = ({ otp, setOtp, isOtpSent, timer, isOtpValid, handleVerifyOTP }) => {
  const handleChange = (e) => {
    const value = e.target.value.replace(/\D/g, EMPTY);
    if (value.length <= LENGTH_OF_OTP) {
      setOtp(value);
    }
  };
  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && otp.length === LENGTH_OF_OTP && isOtpValid) {
      handleVerifyOTP(e);
    }
  };

  return (
    <TextField
      {...OTP_INPUT_PROPS}
      value={otp}
      onChange={handleChange}
      onKeyDown={handleKeyPress}
      disabled={!isOtpSent || timer === 0}
      helperText={!isOtpValid && otp.length > 0 ? HELPER_TEXT.SHORT_OTP_LENGTH : EMPTY}
      slotProps={{
        input: {
          maxLength: LENGTH_OF_OTP,
          pattern: MOBILE_OR_OTP_INPUT_PATTERN
        }
      }}
    />
  )
};

const SendOtpButton = ({ isEmailValid, isMobileValid, isSending, isCheckingUserExist }) => (
  <Button
    {...SEND_OTP_BUTTON_PROPS}
    className={isEmailValid || isMobileValid ? EMPTY : styles.inactiveButton}
    disabled={isSending || isCheckingUserExist}
  >
    {isSending || isCheckingUserExist ? (
      <CircularProgress size={CIRCULAR_PROGRESS_SIZE} className={styles.loadingSpinner} />
    ) : (
      SEND_OTP_BUTTON_TEXT
    )}
  </Button>
);

const VerifyOtpButton = ({ isOtpSent, isVerifying, handleVerifyOTP}) => (
  
  <Button
    {...VERIFY_OTP_BUTTON_PROPS}
    onClick={handleVerifyOTP}
    disabled={!isOtpSent || isVerifying}
  >
    {isVerifying ? (
      <CircularProgress size={CIRCULAR_PROGRESS_SIZE} className={styles.loadingSpinner} />
    ) : (
      VERIFY_OTP_BUTTON_TEXT
    )}
  </Button>
);

const SwitchLink = ({ formType, navigate }) => (
  <Typography
    {...SWITCH_LINK_TYPOGRAPHY_PROPS}
    className={styles.link}
    onClick={() => formType === FORM_TYPES.LOGIN ? navigate(ROUTES.REGISTER) : navigate(ROUTES.LOGIN)}
  >
    {formType === FORM_TYPES.LOGIN ? REGISTER_LINK_TEXT : LOGIN_LINK_TEXT}
  </Typography>
);

export default AuthForm;
