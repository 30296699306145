import { useCallback } from 'react';
import { api } from '../utils';
import { useDispatch } from 'react-redux';
import { setUser } from '../state';
import { ROUTES } from '../config';
import { API_ENDPOINTS } from '../config/apiEndpoints';

// API Endpoints
const AEP_TO_SEND_MOBILE_OTP = API_ENDPOINTS.SEND_MOBILE_OTP_API;
const AEP_TO_VERIFY_MOBILE_OTP = API_ENDPOINTS.VERIFY_MOBILE_OTP_API;

// Messages
const MESSAGES = {
  OTP_SENT: 'OTP sent to your mobile.',
  OTP_SEND_ERROR: 'Error sending OTP. Please try again.',
  OTP_VERIFY_SUCCESS: 'Operation successful!',
  OTP_VERIFY_ERROR: 'Error verifying OTP. Please try again.',
  OTP_INVALID: 'Invalid OTP. Please try again.',
};


const useMobileOTP = ({ mobile, otp, setIsSending, setIsVerifying, setMessage, setIsOtpSent, navigate }) => {
  const dispatch = useDispatch();

  const sendMobileOTP = useCallback(async () => {
    setIsSending(true);
    try {
      const response = await api.post(AEP_TO_SEND_MOBILE_OTP, { mobile_number:mobile });
      if (response.status === 200 || response.status === 201) {
        setMessage(MESSAGES.OTP_SENT);
        setIsOtpSent(true);
      }
    } catch (error) {
      setMessage(MESSAGES.OTP_SEND_ERROR);
    } finally {
      setIsSending(false);
    }
  }, [mobile, setIsSending, setMessage, setIsOtpSent]);

  const verifyMobileOTP = useCallback(async () => {
    setIsVerifying(true);
    try {
      const response = await api.post(AEP_TO_VERIFY_MOBILE_OTP, { mobile_number: mobile, otp }, { withCredentials: true });
      if (response.status === 200) {
        const { data } = response;
        dispatch(setUser({
          name: data.data.name,
          email: data.data.email,
          mobile_number: mobile,
          role: data.data.role,
          is_verified: data.data.is_verified,
          is_profile_completed: data.data.is_profile_completed,
          access_token: data.data.access_token,
        }));

        if (data.data.is_profile_completed) {
          navigate(ROUTES.HOME);
        } else {
          navigate(ROUTES.ADD_DETAILS);
        }
        setMessage(MESSAGES.OTP_VERIFY_SUCCESS);
      } 
    } catch (error) {
      setMessage(error.response.data.message || MESSAGES.OTP_VERIFY_ERROR);
    } finally {
      setIsVerifying(false);
    }
  }, [mobile, otp, setIsVerifying, setMessage, navigate, dispatch]);

  return { sendMobileOTP, verifyMobileOTP };
};

export default useMobileOTP;
