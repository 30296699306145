import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setLogout } from "../state";
import api from "../utils";
import { useBackdrop } from "../context/BackdropContext";
import { ROUTES } from "../config";
import useSnackbarAlert from "./useSnackbarAlert";
import { API_ENDPOINTS } from "../config/apiEndpoints";


// API Endpoint
const AEP_FOR_LOGOUT = API_ENDPOINTS.LOGOUT_API;

const ROUTE_TO_LOGIN = ROUTES.LOGIN;
const CONSOLE_ERROR_MESSAGE = "An error occurred during logout.";
const ERROR_ALERT_MESSAGE = "An error occurred during logout. Try again later.";
const ALERTY_SEVERITY_TYPE = "error";

const useLogout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useSelector((state) => state.user.accessToken);
  const { showBackdrop, hideBackdrop } = useBackdrop();
  const { showSnackbar, SnackbarComponent } = useSnackbarAlert();

  const handleLogout = async () => {
    showBackdrop();
    try {
      const response = await api.post(
        AEP_FOR_LOGOUT,
        { withCredentials: true },
        {
          headers: {
            Authorization: `Bearer ${token}`, // Add Authorization header
          },
        }
      );

      if (response.status === 200 || response.status === 201) {
        dispatch(setLogout());
        navigate(ROUTE_TO_LOGIN);
      }
    } catch (error) {
      showSnackbar(ERROR_ALERT_MESSAGE, ALERTY_SEVERITY_TYPE);
      console.error(CONSOLE_ERROR_MESSAGE, error);
    } finally {
      hideBackdrop();
    }
  };

  return { handleLogout, SnackbarComponent };
};

export default useLogout;
