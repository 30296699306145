import { useState, useCallback } from 'react';
import api from '../utils';
import { useBackdrop } from '../context/BackdropContext';
import useSnackbarAlert from './useSnackbarAlert';
import { API_ENDPOINTS } from '../config/apiEndpoints';

const AEP_TO_GET_ALL_DEVICES_OF_SITE = API_ENDPOINTS.GET_ALL_DEVICES_OF_A_SITE_API;
const ERROR_MESSAGE = "Error fetching devices. Please try again."
const CONSOLE_ERROR_MESSAGE = "Error fetching devices: ";
const ERROR_ALERT_SEVERITY = "error";
/**
 * Custom hook to fetch devices associated with a specific site.
 * 
 * @param {string} siteId - The ID of the site whose devices need to be fetched.
 * @returns {Object} - An object containing the list of devices, loading state, error state, and a function to manually fetch the devices.
 */
const useFetchDevicesOfSite = (siteId) => {
  const [devices, setDevices] = useState([]);
  const {showBackdrop, hideBackdrop} = useBackdrop();
  const {showSnackbar, SnackbarComponent} = useSnackbarAlert();


 
  const fetchDevices = useCallback(async () => {
    if (!siteId) return; 

    showBackdrop();

    try {
      const encodedSiteId = encodeURIComponent(siteId);
      const response = await api.get(`${AEP_TO_GET_ALL_DEVICES_OF_SITE}/${encodedSiteId}`);
      setDevices(response.data.data); 
    } catch (err) {
      console.error(CONSOLE_ERROR_MESSAGE, err);
      showSnackbar(ERROR_MESSAGE, ERROR_ALERT_SEVERITY);
    } finally {
      hideBackdrop();
    }
  }, [siteId, showBackdrop, hideBackdrop, showSnackbar]);

  return { devices, SnackbarComponent, fetchDevices };
};

export default useFetchDevicesOfSite;
