import React, { useEffect, useMemo, useState } from 'react';
import { List, ListItem, ListItemText, Button, Typography, Card, CardHeader, Checkbox, Divider, Tabs, Tab, Box, Grid2, Paper } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useFetchDevicesOfSite } from '../../hooks';
import styles from './Maintenance.module.css';
import { api } from '../../utils';
import { useSelector } from 'react-redux';

const ACTION = {
  ENTER: 'enter',
  EXIT: 'exit',
};

const MODE = {
  OPERATIONAL: 'operational',
  MAINTENANCE: 'maintenance',
};

const GRID_DIRECTION = {
  ROW: "row",
  COLUMN: "column"
}

const BUTTON = {
  SIZE_SMALL: 'small',
  VARIANT_CONTAINED: 'contained',
  VARIANT_OUTLINED: 'outlined',
};

const TITLE = {
  ACTION_ENTER: 'Devices in Operational Mode',
  ACTION_EXIT: 'Devices in Maintenance Mode',
};

const SUBMIT_BUTTON_TEXT = {
  ACTION_ENTER: 'Enter Maintenance',
  ACTION_EXIT: 'Exit Maintenance',
};

const CUSTOM_LIST_CARD_TITLE = {
  LEFT_CARD: 'Choices',
  RIGHT_CARD: 'Chosen',
};

const LIST_COMPONENT_TYPE = 'div';
const LIST_COMPONENT_ROLE = 'list';

const TABS_LABEL = {
  STATUS: 'Status',
  ENTER: 'Enter Maintenance',
  EXIT: 'Exit Maintenance',
}

const not = (a, b) => a.filter((value) => !b.includes(value));
const intersection = (a, b) => a.filter((value) => b.includes(value));
const union = (a, b) => [...a, ...not(b, a)];

const Maintainance = ({ siteId }) => {
  const [action, setAction] = useState(ACTION.ENTER);
  const [tabIndex, setTabIndex] = useState(0);
  const [left, setLeft] = useState([]);
  const [right, setRight] = useState([]);
  const [checked, setChecked] = useState([]);
  const { devices, fetchDevices, SnackbarComponent } = useFetchDevicesOfSite(siteId);
  const token = useSelector((state) => state.user.accessToken);

  useEffect(() => {
    fetchDevices();
  }, [fetchDevices]);

  const filteredDevices = useMemo(() => {
    return devices.filter((device) =>
      (action === ACTION.ENTER && device.mode === MODE.OPERATIONAL) ||
      (action === ACTION.EXIT && device.mode === MODE.MAINTENANCE)
    );
  }, [devices, action]);

  useEffect(() => {
    setLeft(filteredDevices);
    setRight([]);
    setChecked([]);
  }, [filteredDevices]);

  const enterMaintenance = async () => {
    try {

      const response = await api.post(
        'api/v1/devices/maintenance-mode',
        {
          site_id: siteId,
          action: 'enter',
          devices: rightChecked, // Send the selected device IDs
        },
        {
          headers: {
            Authorization: `Bearer ${token}`, // Include the Bearer token
            'Content-Type': 'application/json',
          },
        }
      );

      // Handle success response
      return response.data;
    } catch (error) {
      console.error('Failed to enter maintenance mode:', error);
      throw error; // Re-throw the error to be caught in handleSubmit
    }
  };

  const exitMaintenance = async () => {
    try {

      const response = await api.post(
        'api/v1/devices/maintenance-mode',
        {
          site_id: siteId,
          action: 'exit',
          devices: rightChecked, // Send the selected device IDs
        },
        {
          headers: {
            Authorization: `Bearer ${token}`, // Include the Bearer token
            'Content-Type': 'application/json',
          },
        }
      );

      // Handle success response
      return response.data;
    } catch (error) {
      console.error('Failed to exit maintenance mode:', error);
      throw error; // Re-throw the error to be caught in handleSubmit
    }
  };


  //handleSubmit
  const handleSubmit = async () => {
    try {
      if (action === ACTION.ENTER) {
        await enterMaintenance();
      } else if (action === ACTION.EXIT) {
        await exitMaintenance();
      }
    } catch (err) {
      console.error('Error occurred:', err);
    }
  };

  const leftChecked = intersection(checked, left.map((device) => device.id));
  const rightChecked = intersection(checked, right.map((device) => device.id));

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items) =>
    intersection(checked, items?.map((device) => device.id)).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items.map((device) => device.id)));
    } else {
      setChecked(union(checked, items.map((device) => device.id)));
    }
  };

  const handleCheckedRight = () => {
    setRight(right.concat(left.filter((device) => leftChecked.includes(device.id))));
    setLeft(not(left, left.filter((device) => leftChecked.includes(device.id))));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(right.filter((device) => rightChecked.includes(device.id))));
    setRight(not(right, right.filter((device) => rightChecked.includes(device.id))));
    setChecked(not(checked, rightChecked));
  };

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
    if (newValue === 1) {
      setAction(ACTION.ENTER);
    } else if (newValue === 2) {
      setAction(ACTION.EXIT);
    }
  };

  const customList = (title, items) => (
    <Card>
      <CardHeader
        className={styles.cardHeader}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={numberOfChecked(items) === items.length && items.length !== 0}
            indeterminate={
              numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0
            }
            disabled={items.length === 0}
            inputProps={{
              'aria-label': 'all items selected',
            }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} selected`}
      />
      <Divider />
      <List className={styles.deviceList} dense component={LIST_COMPONENT_TYPE} role={LIST_COMPONENT_ROLE}>
        {items?.map((device) => {
          const labelId = `transfer-list-all-item-${device.id}-label`;

          return (
            <ListItem key={device.id} role="listitem" onClick={handleToggle(device.id)}>
              <ListItemText id={labelId} primary={device.name} />
              <Checkbox
                checked={checked.includes(device.id)}
                tabIndex={-1}
                disableRipple
                inputProps={{
                  'aria-labelledby': labelId,
                }}
              />
            </ListItem>
          );
        })}
      </List>
    </Card>
  );

  // DataGrid for showing devices with their mode in the "Status" tab
  const columns = [
    { field: 'id', headerName: 'ID', flex: 1 },
    { field: 'name', headerName: 'Device Name', flex: 1 },
    { field: 'mode', headerName: 'Mode', flex: 1 },
  ];

  return (
    <Paper className={styles.maintainancePaper}>
      <Box className={styles.tabBox}>
        <Tabs value={tabIndex} onChange={handleTabChange}>
          <Tab label={TABS_LABEL.STATUS} />
          <Tab label={TABS_LABEL.ENTER} />
          <Tab label={TABS_LABEL.EXIT} />
        </Tabs>
      </Box>

      {/* Status Tab - DataGrid to show devices and their modes */}
      {tabIndex === 0 && (
        <Box>
          <Typography className={styles.title}>Devices Status</Typography>
          
            <DataGrid rows={devices} columns={columns} pageSize={5} disableRowSelectionOnClick autoHeight />
        
        </Box>
      )}

      {/* Enter/Exit Tab Content */}
      {tabIndex !== 0 && (
        <>
          <Typography className={styles.title}>
            {action === ACTION.ENTER ? TITLE.ACTION_ENTER : TITLE.ACTION_EXIT}
          </Typography>
          <Grid2 container spacing={2} className={styles.gridContainer} direction={{ xs: GRID_DIRECTION.COLUMN, md: GRID_DIRECTION.COLUMN, lg: GRID_DIRECTION.ROW }}>
            <Grid2>{customList(CUSTOM_LIST_CARD_TITLE.LEFT_CARD, left)}</Grid2>
            <Grid2>
              <Grid2 container spacing={2} direction={GRID_DIRECTION.COLUMN} className={styles.actionButtons}>
                <Button
                  variant={BUTTON.VARIANT_OUTLINED}
                  size={BUTTON.SIZE_SMALL}
                  onClick={handleCheckedRight}
                  disabled={leftChecked.length === 0}
                >
                  &gt;
                </Button>
                <Button
                  variant={BUTTON.VARIANT_OUTLINED}
                  size={BUTTON.SIZE_SMALL}
                  onClick={handleCheckedLeft}
                  disabled={rightChecked.length === 0}
                >
                  &lt;
                </Button>
              </Grid2>
            </Grid2>
            <Grid2>{customList(CUSTOM_LIST_CARD_TITLE.RIGHT_CARD, right)}</Grid2>
          </Grid2>
          <Grid2 size={{ xs: 12 }} className={styles.submitButton}>
            <Button
              variant={BUTTON.VARIANT_CONTAINED}
              size={BUTTON.SIZE_SMALL}
              onClick={handleSubmit}
              disabled={rightChecked.length === 0} 
            >
              {action === ACTION.ENTER ? SUBMIT_BUTTON_TEXT.ACTION_ENTER : SUBMIT_BUTTON_TEXT.ACTION_EXIT}
            </Button>

          </Grid2>
        </>
      )}
      {SnackbarComponent}
    </Paper>
  );
};

export default Maintainance;