import React, { useState, useEffect } from "react";
import { Box, Tabs, Tab, Typography, Paper } from "@mui/material";
import Dashboard from "../dashboard";
import useFetchDevicesOfUser from "../../hooks/useFetchDevicesOfUser";
import styles from './UserDevices.module.css'; // Import the CSS file

const UserDevices = ({userId}) => {
    const { devices, SnackbarComponent } = useFetchDevicesOfUser(userId);
    const [selectedDeviceId, setSelectedDeviceId] = useState(null);

    const handleChange = (event, newValue) => {
        setSelectedDeviceId(newValue);
    };

    useEffect(() => {
        if (devices?.length > 0) {
            setSelectedDeviceId(devices[0].id); // Set the first device ID as the default selected
        }
    }, [devices]);

    return (
        <Paper className={styles.userDevicesPaper}> 
            {devices.length === 0 ? (
                <Typography className={styles.noDevicesMessage}> 
                    No devices registered for user
                </Typography>
            ) : (
                <>
                    <Box className={styles.deviceTabs}> 
                        <Tabs 
                            value={selectedDeviceId || false} 
                            onChange={handleChange} 
                            aria-label="user devices tabs"
                        >
                            {devices.map((device) => (
                                <Tab 
                                    key={device?.id} 
                                    label={device?.id} 
                                    value={device?.id} 
                                />
                            ))}
                        </Tabs>
                    </Box>
                    <Box className={styles.deviceDashboard}>
                        {selectedDeviceId && (
                            <Dashboard deviceId={selectedDeviceId} /> 
                        )}
                    </Box>
                </>
            )}
            {SnackbarComponent}
        </Paper>
    );
};

export default UserDevices;
