
import { useBackdrop } from '../context/BackdropContext';
import useSnackbarAlert from './useSnackbarAlert';
import api from '../utils';
import { API_ENDPOINTS } from '../config/apiEndpoints';


const AEP_TO_INTIMATE_FIRMWARE = API_ENDPOINTS.INTIMATE_LATEST_FIRMWARE_VERSION_API;
const useIntimateFirmware = () => {
    const{showBackdrop,hideBackdrop}=useBackdrop()
    const {showSnackbar,SnackbarComponent} = useSnackbarAlert();

    const intimateFirmwareVersion = async (version, siteId) => {
        showBackdrop();
        try {
             await  api.post(AEP_TO_INTIMATE_FIRMWARE, {
                version,
                site_id: siteId,
            });
            showSnackbar('Submitted successfully', 'success'); // Return the response or handle it as needed
        } catch (err) {
            console.error('Error submitting devices:', err);
            showSnackbar('Failed to submit devices. Please try again.', 'error'); // Re-throw the error to be handled by the component if necessary
        } finally {
            hideBackdrop();
        }
    };

    return { intimateFirmwareVersion, SnackbarComponent};
};

export default useIntimateFirmware;
