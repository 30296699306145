import React from 'react';
import styles from './HomePage.module.css';
import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { Help, Navbar, Sidebar } from '../../components';

const Home = () => {

  return (
    <Box className={styles.homePage}>

      <Navbar />

      <Box className={styles.homeContainer} >


        <Box className={styles.sidebar}>
          <Sidebar />
        </Box>



        <Box className={styles.dashboard} >
          <Outlet />
        </Box>


        <Box className={styles.help}>
          <Help />
        </Box>


      </Box>

    </Box>
  );
};

export default Home;
