import api from '../utils';
import useSnackbarAlert from './useSnackbarAlert';
import { useBackdrop } from '../context/BackdropContext';
import { API_ENDPOINTS } from '../config/apiEndpoints';

const AEP_TO_ASSIGN_DEVICE = API_ENDPOINTS.ASSIGN_DEVICE_TO_USER_API;
const AEP_TO_DEASSIGN_DEVICE = API_ENDPOINTS.DEASSIGN_DEVICE_API;

// Constants for error/success messages
const ERROR_ASSIGNING_DEVICE = 'Error assigning device:';
const ERROR_DEASSIGNING_DEVICE = 'Error deassigning device:';
const ASSIGN_SUCCESS_MESSAGE = 'Device assigned successfully';
const DEASSIGN_SUCCESS_MESSAGE = 'Device deassigned successfully';
const MISSING_DEVICE_OR_USER_ID = 'Device ID and User ID are required';
const MISSING_DEVICE_OR_USER_EMAIL = 'Device ID and User Email are required';

const ALERT_SEVERITY = {
    SUCCESS: 'success',
    ERROR: 'error',
}

const useDeviceManagement = (token, fetchDevices) => {
  const { showBackdrop, hideBackdrop } = useBackdrop();
  const { showSnackbar, SnackbarComponent } = useSnackbarAlert();

  const assignDevice = async (deviceId, userId) => {
    showBackdrop();
    try {
      if (!deviceId || !userId) {
        throw new Error(MISSING_DEVICE_OR_USER_ID);
      }

      await api.post(AEP_TO_ASSIGN_DEVICE, { device_id: deviceId, user_id: userId }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      showSnackbar(ASSIGN_SUCCESS_MESSAGE, ALERT_SEVERITY.SUCCESS);
      await fetchDevices();
    } catch (err) {
      showSnackbar(err.message, ALERT_SEVERITY.ERROR);
      console.error(ERROR_ASSIGNING_DEVICE, err);
    } finally {
      hideBackdrop();
    }
  };

  const deassignDevice = async (deviceId, userEmail) => {
    showBackdrop();
    try {
      if (!deviceId || !userEmail) {
        throw new Error(MISSING_DEVICE_OR_USER_EMAIL);
      }

      await api.post(AEP_TO_DEASSIGN_DEVICE, { device_id: deviceId, user_email: userEmail }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      showSnackbar(DEASSIGN_SUCCESS_MESSAGE, ALERT_SEVERITY.SUCCESS);
      await fetchDevices();
    } catch (err) {
      showSnackbar(err.message, ALERT_SEVERITY.ERROR);
      console.error(ERROR_DEASSIGNING_DEVICE, err);
    } finally {
      hideBackdrop();
    }
  };

  return {
    assignDevice,
    deassignDevice,
    SnackbarComponent,
  };
};

export default useDeviceManagement;
