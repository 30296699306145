// hooks/useFirmwareVersions.js
import { useState, useEffect } from 'react';
import api from '../utils';
import { API_ENDPOINTS } from '../config/apiEndpoints';


const AEP_TO_GET_ALL_FIRMWARE_VERSIONS_OF_SITE = API_ENDPOINTS.FIRMWARE_VERSIONS_OF_SITE_API;
const useFirmwareVersions = (siteId) => {
    const [versions, setVersions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchVersions = async () => {
            setLoading(true);
            setError(null); // Reset the error state before fetching
            try {
                const response = await api.get(`${AEP_TO_GET_ALL_FIRMWARE_VERSIONS_OF_SITE}${siteId}`);
                setVersions(response.data.data || []); // Assuming 'data' is the key where versions are stored
            } catch (err) {
                console.error('Error fetching firmware versions:', err);
                setError(err.message || 'Error fetching firmware versions');
            } finally {
                setLoading(false);
            }
        };

        if (siteId) {
            fetchVersions();
        }
    }, [siteId]);

    return { versions, loading, error };
};

export default useFirmwareVersions;
