import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { TextField, Button, Typography, CircularProgress, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, Autocomplete } from '@mui/material';
import { useSelector } from 'react-redux';
import styles from './UserDetailsForm.module.css';
import { useCompleteProfile } from '../../hooks';
import api, { validateEmail, validateMobileNumber } from '../../utils';
import { AddressInput, EmailInput, MobileNumberInput, NameInput } from '../inputFeilds';

const ROLE_LABEL = 'Role:';
const CONSUMER_LABEL = 'Consumer';
const SITE_ADMIN_LABEL = 'Site Admin';
const SELECT_SITE_LABEL = 'Select site';
const SUBMIT_BUTTON_TEXT = 'Submit';

const ROLES = {
  CONSUMER: 'consumer',
  SITE_ADMIN: 'site_admin',
};

const TEXTFIELD_PROPS = {
  variant: 'outlined',
  margin: 'dense',
  fullWidth: true,
};

const NAME_INPUT_PROPS = {
  name: 'name',
  label: 'Name',
  type: 'text',
  required: true,
  ...TEXTFIELD_PROPS,
};

const EMAIL_INPUT_PROPS = {
  name: 'email',
  label: 'Email',
  type: 'email',
  required: true,
  ...TEXTFIELD_PROPS,
};

const MOBILE_INPUT_PROPS = {
  name: 'mobile_number',
  label: 'Mobile Number',
  type: 'numeric',
  required: true,
  ...TEXTFIELD_PROPS,
};

const ADDRESS_INPUT_PROPS = {
  name: 'address',
  label: 'Address',
  type: 'text',
  required: true,
  ...TEXTFIELD_PROPS,
};

const RADIO_BUTTON_PROPS = {
  size: 'small',
};

const ROLE_SELECTION_RADIO_GROUP_PROPS = {
  size: 'small',
  row: true,
};

const ROLE_SELECTION_FORM_CONTROL_PROPS = {
  component: 'fieldset',
  margin: 'normal',
};

const SELECT_SITE_PROPS = {
  ...TEXTFIELD_PROPS,
  name: 'site_id',
  required: true,
};

const SUBMIT_BUTTON_PROPS = {
  type: 'submit',
  variant: 'contained',
  color: 'primary',
};

const MESSAGE_TYPOGRAPHY_PROPS = {
  variant: 'body2',
};

const EMPTY = '';

const CIRCULAR_PROGRESS_SIZE = 24;

const UserDetailsForm = () => {
  const userEmail = useSelector((state) => state.user.email);
  const userName = useSelector((state) => state.user.name);
  const userMobile = useSelector((state) => state.user.mobileNumber);
  const [name, setName] = useState(userName || EMPTY);
  const [email, setEmail] = useState(userEmail || EMPTY);
  const [mobile, setMobile] = useState(userMobile || EMPTY);
  const [address, setAddress] = useState(EMPTY);
  const [role, setRole] = useState(ROLES.CONSUMER);
  const [siteOptions, setSiteOptions] = useState([]);
  const [dropdownValue, setDropdownValue] = useState(null);

  const isEmailValid = useMemo(() => validateEmail(email), [email]);
  const isMobileValid = useMemo(() => validateMobileNumber(mobile), [mobile]);
  const isNameValid = useMemo(() => name?.length >= 4, [name]);
  const isAddressValid = useMemo(() => address?.length >= 10, [address]);

  const isFormValid = useMemo(() => {
    return (
      isEmailValid &&
      isMobileValid &&
      isNameValid &&
      isAddressValid
    );
  }, [isEmailValid, isMobileValid, isNameValid, isAddressValid]);

  const { submitProfileDetails, isSubmitting, message } = useCompleteProfile();

  const handleRoleChange = useCallback((e) => {
    const selectedRole = e.target.value;
    setRole(selectedRole);
  }, []);

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    await submitProfileDetails({
      name,
      mobile_number: mobile,
      email,
      address,
      role,
      site_id: dropdownValue?.id,
    });
  }, [name, mobile, email, address, role, dropdownValue, submitProfileDetails]);

  // Fetch site options from backend when component mounts
  useEffect(() => {
    const fetchSites = async () => {
      try {
        const response = await api.get('/api/v1/sites/get-all-sites');
        setSiteOptions(response.data.data); // Assuming the API returns an array of site options
      } catch (error) {
        console.error('Error fetching site options:', error);
      }
    };
    fetchSites();
  }, []);

  return (
    <form onSubmit={handleSubmit} className={styles.userDetailsForm}>
      <NameInput {...NAME_INPUT_PROPS} name={name} setName={setName} isSubmitting={isSubmitting} />
      <EmailInput {...EMAIL_INPUT_PROPS} email={email} setEmail={setEmail} isSubmitting={isSubmitting} isEmailValid={isEmailValid} />
      <MobileNumberInput {...MOBILE_INPUT_PROPS} mobile={mobile} setMobile={setMobile} isMobileValid={isMobileValid} isSubmitting={isSubmitting} />
      <AddressInput {...ADDRESS_INPUT_PROPS} address={address} setAddress={setAddress} isSubmitting={isSubmitting} />
      <SiteSelection value={dropdownValue} siteOptions={siteOptions} role={role} onChange={(event, newValue) => setDropdownValue(newValue)} isSubmitting={isSubmitting} />
      <RoleSelection role={role} handleRoleChange={handleRoleChange} />

      <Button {...SUBMIT_BUTTON_PROPS} disabled={isSubmitting || !isFormValid} className={isFormValid ? EMPTY : styles.inactiveButton}>
        {isSubmitting ? <CircularProgress size={CIRCULAR_PROGRESS_SIZE} className={styles.loadingSpinner} /> : SUBMIT_BUTTON_TEXT}
      </Button>
      {message && (
        <Typography className={styles.errorMessage} {...MESSAGE_TYPOGRAPHY_PROPS}>
          {message}
        </Typography>
      )}
    </form>
  );
};

const RoleSelection = ({ role, handleRoleChange }) => (
  <FormControl {...ROLE_SELECTION_FORM_CONTROL_PROPS}>
    <FormLabel>{ROLE_LABEL}</FormLabel>
    <RadioGroup value={role} onChange={handleRoleChange} {...ROLE_SELECTION_RADIO_GROUP_PROPS}>
      <FormControlLabel value={ROLES.CONSUMER} control={<Radio {...RADIO_BUTTON_PROPS} />} label={CONSUMER_LABEL} />
      <FormControlLabel value={ROLES.SITE_ADMIN} control={<Radio {...RADIO_BUTTON_PROPS} />} label={SITE_ADMIN_LABEL} />
    </RadioGroup>
  </FormControl>
);

const SiteSelection = ({ value, onChange, siteOptions }) => (
  <Autocomplete
    options={siteOptions}
    fullWidth
    getOptionLabel={(option) => option.name}
    value={value}
    onChange={onChange}
    renderInput={(params) => (
      <TextField
        {...params}
        label={SELECT_SITE_LABEL}
        {...SELECT_SITE_PROPS}
      />
    )}
  />
);

export default UserDetailsForm;