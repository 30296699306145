import React from 'react';
import { Backdrop, CircularProgress } from '@mui/material';
import { useBackdrop } from '../../context/BackdropContext';


const GlobalBackdrop = () => {
  const { open } = useBackdrop();

  return (
    <Backdrop open={open} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default GlobalBackdrop;
