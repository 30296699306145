import { useCallback } from 'react';
import { api } from '../utils';
import { useDispatch } from 'react-redux';
import { setUser } from '../state';
import { ROUTES } from '../config';
import { API_ENDPOINTS } from '../config/apiEndpoints';

// API Endpoints
const AEP_TO_SEND_OTP = API_ENDPOINTS.SEND_EMAIL_OTP_API;
const AEP_TO_VERIFY_OTP = API_ENDPOINTS.VERIFY_EMAIL_OTP_API;

// Messages
const MESSAGES = {
  OTP_SENT: 'OTP sent to your email.',
  OTP_SEND_ERROR: 'Error sending OTP. Please try again.',
  OTP_VERIFY_SUCCESS: 'Operation successful!',
  OTP_VERIFY_ERROR: 'Error verifying OTP. Please try again.',
  OTP_INVALID: 'Invalid OTP. Please try again.',
};


const useEmailOTP = ({ email, otp, setIsSending, setIsVerifying, setMessage, setIsOtpSent, navigate }) => {
  const dispatch = useDispatch();

  const sendEmailOTP = useCallback(async () => {
    setIsSending(true);
    try {
      const response = await api.post(AEP_TO_SEND_OTP, { email });
      if (response.status === 200 || response.status === 201) {
        setMessage(MESSAGES.OTP_SENT);
        setIsOtpSent(true);
      }
    } catch (error) {
      setMessage(MESSAGES.OTP_SEND_ERROR);
    } finally {
      setIsSending(false);
    }
  }, [email, setIsSending, setMessage, setIsOtpSent]);

  const verifyEmailOTP = useCallback(async () => {
    setIsVerifying(true);
    try {
      const response = await api.post(AEP_TO_VERIFY_OTP, { email, otp }, { withCredentials: true });
      if (response.status === 200) {
        const { data } = response;
        dispatch(setUser(data.data));
        if (data.data.is_profile_completed) {
          navigate(ROUTES.HOME);
        } else {
          navigate(ROUTES.ADD_DETAILS);
        }
        setMessage(MESSAGES.OTP_VERIFY_SUCCESS);
      } 
    } catch (error) {
      setMessage(error.response.data.message || MESSAGES.OTP_VERIFY_ERROR);
    } finally {
      setIsVerifying(false);
    }
  }, [email, otp, setIsVerifying, setMessage, navigate, dispatch]);

  return { sendEmailOTP, verifyEmailOTP };
};

export default useEmailOTP;
