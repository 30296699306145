import { useState, useCallback } from 'react';
import { Snackbar, Alert } from '@mui/material';


const EMPTY = '';
const DEFAULT_SEVERITY = 'success';
const SNACKBAR_POSITION = { vertical: 'top', horizontal: 'center' };
const AUTOHIDE_DURATION = 3000;


const useSnackbarAlert = () => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState(EMPTY);
  const [snackbarSeverity, setSnackbarSeverity] = useState(DEFAULT_SEVERITY);

  const showSnackbar = useCallback((message, severity = DEFAULT_SEVERITY) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  }, []);

  const handleSnackbarClose = useCallback(() => {
    setSnackbarOpen(false);
  }, []);

  const SnackbarComponent = (
    <Snackbar
      open={snackbarOpen}
      anchorOrigin={SNACKBAR_POSITION}
      autoHideDuration={AUTOHIDE_DURATION}
      onClose={handleSnackbarClose}
    >
      <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
        {snackbarMessage}
      </Alert>
    </Snackbar>
  );

  return { showSnackbar, SnackbarComponent };
};

export default useSnackbarAlert;
