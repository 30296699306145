import React, { useEffect, useState, useMemo, useCallback } from 'react';
import {
  Typography, Paper, Dialog, DialogTitle, DialogContent, DialogActions,
  Autocomplete, TextField, Button, Tab, Tabs, Box,
  IconButton
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useSelector } from 'react-redux';
import { useFetchConsumersOfSite, useFetchDevicesOfSite } from '../../hooks';
import styles from './DeviceManagement.module.css';
import { Close, WarningAmber } from '@mui/icons-material';
import useDeviceManagement from '../../hooks/useDeviceManagement'; // Import the custom hook

// Constants
const ASSIGN_DEVICE_TAB = 0;
const DEASSIGN_DEVICE_TAB = 1;
const ASSIGN_DEVICE_LABEL = 'Assign Devices';
const DEASSIGN_DEVICE_LABEL = 'Deassign Devices';
const ASSIGN_TO_USER = 'Assign to User';
const DEASSIGN = 'Deassign';
const DEVICE_MANAGEMENT_TITLE = 'Device Management';
const ASSIGN_DEVICE_DIALOG_TITLE = 'Assign Device';
const DEASSIGN_DEVICE_DIALOG_TITLE = 'Deassign Device';
const CANCEL_BUTTON_LABEL = 'Cancel';
const SUBMIT_BUTTON_LABEL = 'Submit';

const ASSIGNMENT_STATUS = {
  ASSIGNED: 'assigned',
  UNASSIGNED: 'unassigned',
};

const DATA_GRID_FIELDS = {
  ID: 'id',
  NAME: 'name',
  MODE: 'mode',
  ASSIGNED_USER: 'assigned_user',
  ACTION: 'action',
};

const DATA_GRID_HEADER_NAMES = {
  ID: 'Device ID',
  NAME: 'Device Name',
  DEVICE_MODE: 'Device Mode',
  ASSIGNED_USER: 'Assigned User',
  UNASSIGNED_DEVICE: 'Unassigned Device',
  ASSIGNED_DEVICE: 'Assigned Device',
  ACTION: 'Action',
};

const DAILOG_CLOSE_REASON = 'backdropClick';
const ICON_BUTTON_ARIA_LABEL = "close";

const COMPONENT_TITLE_VARIANT = 'h5';

const DIALOG_CONTENT_SUB_HEADING = {
  DEVICE: 'Device : ',
  CURRENT_USER: 'Current User : ',
}

const AUTOCOMPLETE_LABEL = "Select User";

const BUTTON_COLOR = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
}

const BUTTON_VARIANT = {
  CONTAINED: 'contained',
}

const DEASSIGN_MESSAGE = (deviceName, assignedUser) =>
  `Are you sure you want to deassign the device ${deviceName} from ${assignedUser}?`;

// Main Component
const DeviceManagement = () => {
  const siteId = useSelector((state) => state.user.siteId);
  const token = useSelector((state) => state.user.accessToken);
  const { consumers } = useFetchConsumersOfSite(siteId);
  const { devices, fetchDevices } = useFetchDevicesOfSite(siteId);
  const { assignDevice, deassignDevice, SnackbarComponent } = useDeviceManagement(token, fetchDevices);

  useEffect(() => {
    fetchDevices();
  }, [fetchDevices]);

  const [tab, setTab] = useState(ASSIGN_DEVICE_TAB);
  const [open, setOpen] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);

  // Memoizing device lists to optimize performance
  const assignedDevices = useMemo(() => devices.filter((device) => device.assignment_status === ASSIGNMENT_STATUS.ASSIGNED), [devices]);
  const unassignedDevices = useMemo(() => devices.filter((device) => device.assignment_status === ASSIGNMENT_STATUS.UNASSIGNED), [devices]);

  const handleOpenModal = useCallback((device) => {
    setSelectedDevice(device);
    setOpen(true);
  }, []); 

  const handleCloseModal = () => {
    setOpen(false);
    setSelectedDevice(null);
    setSelectedUser(null);
  };

  const handleSubmit = async () => {
    if (tab === ASSIGN_DEVICE_TAB) {
      await assignDevice(selectedDevice?.id, selectedUser?.id);
    } else {
      await deassignDevice(selectedDevice?.id, selectedDevice?.assigned_user);
    }
    handleCloseModal();
  };

  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };

  // Memoizing columns for performance optimization
  const unassignedColumns = useMemo(() => getUnassignedColumns(handleOpenModal), [handleOpenModal]);
  const assignedColumns = useMemo(() => getAssignedColumns(handleOpenModal), [handleOpenModal]);

  return (
    <Paper className={styles.paperContainer}>
      <Typography variant={COMPONENT_TITLE_VARIANT} gutterBottom>
        {DEVICE_MANAGEMENT_TITLE}
      </Typography>
      <Box className={styles.tabBox}>
        <Tabs value={tab} onChange={handleChangeTab}>
          <Tab label={ASSIGN_DEVICE_LABEL} />
          <Tab label={DEASSIGN_DEVICE_LABEL} />
        </Tabs>
      </Box>

      {tab === ASSIGN_DEVICE_TAB && (
        <DeviceTable
          rows={unassignedDevices}
          columns={unassignedColumns}
        />
      )}

      {tab === DEASSIGN_DEVICE_TAB && (
        <DeviceTable
          rows={assignedDevices}
          columns={assignedColumns}
        />
      )}

      <DeviceDialog
        open={open}
        tab={tab}
        handleCloseModal={handleCloseModal}
        handleSubmit={handleSubmit}
        selectedDevice={selectedDevice}
        consumers={consumers}
        selectedUser={selectedUser}
        setSelectedUser={setSelectedUser}
      />

      {SnackbarComponent}
    </Paper>
  );
};

// Subcomponent: DeviceTable
const DeviceTable = ({ rows, columns }) => (
  <DataGrid
    rows={rows}
    columns={columns}
    pageSize={5}
    rowsPerPageOptions={[5]}
    disableSelectionOnClick
    getRowId={(row) => row.id}
    autoHeight
  />
);

// Subcomponent: DeviceDialog
const DeviceDialog = ({
  open, tab, handleCloseModal, handleSubmit, selectedDevice,
  consumers, selectedUser, setSelectedUser
}) => (
  <Dialog open={open} onClose={(event, reason) => {
    if (reason !== DAILOG_CLOSE_REASON) {
      handleCloseModal();
    }
  }} PaperProps={{ className: styles.dialog }}>
    <DialogTitle className={styles.dialogTitle}>
      {tab === DEASSIGN_DEVICE_TAB ? (
        <>
          <WarningAmber className={styles.warningIcon} />
          {DEASSIGN_DEVICE_DIALOG_TITLE}
        </>
      ) : (
        ASSIGN_DEVICE_DIALOG_TITLE
      )}
      <IconButton
        aria-label={ICON_BUTTON_ARIA_LABEL}
        onClick={handleCloseModal}
        className={styles.dialogIconButton}
      >
        <Close />
      </IconButton>
    </DialogTitle>

    <DialogContent className={styles.dialogContent}>
      <Typography className={styles.dialogContentTypography}>
        {DIALOG_CONTENT_SUB_HEADING.DEVICE} <strong>{selectedDevice?.name}</strong>
      </Typography>
      {tab === DEASSIGN_DEVICE_TAB && (
        <Typography className={styles.dialogContentTypography}>
          {DIALOG_CONTENT_SUB_HEADING.CURRENT_USER} <strong>{selectedDevice?.assigned_user}</strong>
        </Typography>
      )}

      {tab === ASSIGN_DEVICE_TAB ? (
        <Autocomplete
          options={consumers}
          getOptionLabel={(user) => user.name || user.email}
          onChange={(event, newValue) => setSelectedUser(newValue)}
          renderInput={(params) => <TextField {...params} label={AUTOCOMPLETE_LABEL} />}
        />
      ) : (
        <Typography className={styles.deassignTypography}>
          {DEASSIGN_MESSAGE(
            <strong>{selectedDevice.name}</strong>,
            <strong>{selectedDevice.assigned_user}</strong>
          )}
        </Typography>
      )}
    </DialogContent>

    <DialogActions>
      <Button onClick={handleCloseModal} color={BUTTON_COLOR.SECONDARY}>{CANCEL_BUTTON_LABEL}</Button>
      <Button onClick={handleSubmit} color={BUTTON_COLOR.PRIMARY} disabled={tab === ASSIGN_DEVICE_TAB && !selectedUser}>
        {SUBMIT_BUTTON_LABEL}
      </Button>
    </DialogActions>
  </Dialog>
);

// Helper: Unassigned Device Columns
const getUnassignedColumns = (handleOpenModal) => [
  { field: DATA_GRID_FIELDS.NAME, headerName: DATA_GRID_HEADER_NAMES.UNASSIGNED_DEVICE, flex: 1 },
  { field: DATA_GRID_FIELDS.MODE, headerName: DATA_GRID_HEADER_NAMES.DEVICE_MODE, flex: 1 },
  {
    field: DATA_GRID_FIELDS.ACTION,
    headerName: DATA_GRID_HEADER_NAMES.ACTION,
    flex: 1,
    renderCell: (params) => (
      <Button
        variant={BUTTON_VARIANT.CONTAINED}
        onClick={() => handleOpenModal(params.row)}
        className={styles.assignDeassignButton}
      >
        {ASSIGN_TO_USER}
      </Button>
    ),
  },
];

// Helper: Assigned Device Columns
const getAssignedColumns = (handleOpenModal) => [
  { field: DATA_GRID_FIELDS.NAME, headerName: DATA_GRID_HEADER_NAMES.ASSIGNED_DEVICE, flex: 1 },
  { field: DATA_GRID_FIELDS.MODE, headerName: DATA_GRID_HEADER_NAMES.DEVICE_MODE, flex: 1 },
  { field: DATA_GRID_FIELDS.ASSIGNED_USER, headerName: DATA_GRID_HEADER_NAMES.ASSIGNED_USER, flex: 1 },
  {
    field: DATA_GRID_FIELDS.ACTION,
    headerName: DATA_GRID_HEADER_NAMES.ACTION,
    flex: 1,
    renderCell: (params) => (
      <Button
        variant={BUTTON_VARIANT.CONTAINED}
        onClick={() => handleOpenModal(params.row)}
        className={styles.assignDeassignButton}
      >
        {DEASSIGN}
      </Button>
    ),
  },
];

export default DeviceManagement;
