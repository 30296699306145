import React from 'react';
import { Box, Typography, Paper, Button } from '@mui/material';
import styles from './DefaultDashboard.module.css';

const DefaultDashboard = () => {
  return (
    <Box className={styles.container}>
      <Paper className={styles.paper} elevation={6}>
        <Typography variant="h3" className={styles.title}>
          Welcome to Your Dashboard!
        </Typography>
        <Typography variant="h6" className={styles.subtitle}>
          Congratulations! You have successfully logged in.
        </Typography>
        <Typography variant="body1" className={styles.description}>
          You can now start exploring your dashboard. Use the sidebar to navigate to different sections, manage your devices, and review your profile. If you need any assistance, our help section is just a click away.
        </Typography>
        <Box className={styles.buttonContainer}>
          <Button
            variant="contained"
            color="primary"
            href="/user/profile"
            className={styles.button}
          >
            View Profile
          </Button>
          {/*       */}
        </Box>
      </Paper>
    </Box>
  );
};

export default DefaultDashboard;
