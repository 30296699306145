import axios from 'axios';
import {jwtDecode} from 'jwt-decode';
import dayjs from 'dayjs';
import { store } from '../state/store';
import { setUser, setLogout } from '../state';
import { API_ENDPOINTS } from '../config/apiEndpoints';

// Error messages
const ERROR_MESSAGE_FOR_REFRESH_TOKEN_FUNCTION = 'Failed to refresh access token';
const ERROR_MESSAGE_FOR_TOKEN_EXPIRATION = 'Redirecting to login due to token expiration';

// Base API configuration
const baseURL = process.env.REACT_APP_SERVER_URL;

// eslint-disable-next-line
const AEP_FOR_REFRESH_TOKEN = API_ENDPOINTS.REFRESH_TOKEN_API;


export const api = axios.create({
  baseURL,
});

// Function to refresh the access token
const refreshToken = async () => {
  try {
    const response = await axios.post("http://localhost:4000/api/v1/auth/refresh-token", {}, { withCredentials: true });
    return response.data.data.access_token;
  } catch (error) {
    console.error(ERROR_MESSAGE_FOR_REFRESH_TOKEN_FUNCTION, error);
    store.dispatch(setLogout());
    throw error;
  }
};

// Axios request interceptor to handle token refreshing
api.interceptors.request.use(async (config) => {
  const state = store.getState();
  const accessToken = state.user.accessToken;

  if (accessToken) {
    const decodedToken = jwtDecode(accessToken);
    const isExpired = dayjs.unix(decodedToken.exp).isBefore(dayjs());
    if (!isExpired) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    } else {
      try {
        const newAccessToken=await refreshToken();
        store.dispatch(setUser({  access_token: newAccessToken }));
        config.headers.Authorization = `Bearer ${newAccessToken}`;
      } catch (error) {
        console.error(ERROR_MESSAGE_FOR_TOKEN_EXPIRATION,error);
        store.dispatch(setLogout());
      }
    }
  }

  return config;
}, (error) => {
  return Promise.reject(error);
});

export default api;

// Validation functions
export const validateEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export const validateMobileNumber = (mobile) => {
  const regex = /^[6-9]\d{9}$/; // Indian mobile number validation
  return regex.test(mobile);
}

export const validateOTP = (otp) => {
  const regex = /^\d{6}$/; // OTP should be exactly 6 digits
  return regex.test(otp);
};
