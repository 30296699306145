import { useGoogleLogin } from '@react-oauth/google';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import api from '../utils';
import { setUser } from '../state';
import useMessage from './useMessage';
import { ROUTES } from '../config';
import { API_ENDPOINTS } from '../config/apiEndpoints';

// API Endpoints
const AEP_FOR_SIGNIN_WITH_GOOGLE = API_ENDPOINTS.GOOGLE_SIGN_IN_API;

// Messages
const ERROR_MESSAGE='Google login failed. Please try again.'


const useGoogleAuth = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setMessage } = useMessage();

  const handleGoogleLoginSuccess = async (tokenResponse) => {
    try {
      const accessToken = tokenResponse.access_token;
      const response = await api.post(AEP_FOR_SIGNIN_WITH_GOOGLE, {
        googleAccessToken: accessToken,
      }, { withCredentials: true });

      if (response.status === 200 || response.status === 201) {
        dispatch(setUser(response.data.data));
        if (response.data.data.is_profile_completed) {
          navigate(ROUTES.HOME);
        } else {
          navigate(ROUTES.ADD_DETAILS);
        }
      }
    } catch (err) {
      setMessage(ERROR_MESSAGE);
      console.error(err);
    }
  };

  const handleGoogleLogin = useGoogleLogin({ onSuccess: handleGoogleLoginSuccess });

  return {
    handleGoogleLogin,
  };
};

export default useGoogleAuth;
