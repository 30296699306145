import { useState, useEffect } from "react";
import api from "../utils";
import useSnackbarAlert from "./useSnackbarAlert";
import { useBackdrop } from "../context/BackdropContext";
import { API_ENDPOINTS } from "../config/apiEndpoints";

// Constants
const CONSOLE_ERROR_MESSAGE_FETCH_CONSUMERS = "Failed to fetch consumers";
const AEP_TO_GET_ALL_CONSUMERS = API_ENDPOINTS.GET_CONSUMERS_OF_SITE_API;
const ALERT_ERROR_SEVERITY = "error";
const ALERT_ERROR_MESSAGE= "Failed to fetch consumers. Please try again.";

const useFetchConsumersOfSite = (siteId) => {
    const [consumers, setConsumers] = useState([]);
    const { showSnackbar, SnackbarComponent } = useSnackbarAlert();
    const { showBackdrop, hideBackdrop } = useBackdrop();

    useEffect(() => {
        const fetchConsumers = async () => {
            showBackdrop();
            try {
                const response = await api.get(`${AEP_TO_GET_ALL_CONSUMERS}${siteId}`);
                setConsumers(response.data.data);
            } catch (err) {
                showSnackbar(ALERT_ERROR_MESSAGE, ALERT_ERROR_SEVERITY);
                console.error(CONSOLE_ERROR_MESSAGE_FETCH_CONSUMERS, err);
            } finally {
                hideBackdrop();
            }
        };

        fetchConsumers();
    }, [siteId, showBackdrop, hideBackdrop, showSnackbar]);

    return { consumers, SnackbarComponent };
};

export default useFetchConsumersOfSite;
