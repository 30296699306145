export const API_ENDPOINTS = {
    SEND_EMAIL_OTP_API: 'api/v1/auth/send-email-otp',
    VERIFY_EMAIL_OTP_API: 'api/v1/auth/verify-email-otp',
    SEND_MOBILE_OTP_API: 'api/v1/auth/send-mobile-otp',
    VERIFY_MOBILE_OTP_API: 'api/v1/auth/verify-mobile-otp',
    CHECK_USER_EXIST_API: 'api/v1/auth/user-exist',
    GOOGLE_SIGN_IN_API: 'api/v1/auth/signin-with-google',
    REFRESH_TOKEN_API: 'api/v1/auth/refresh-token',
    COMPLETE_PROFILE_API: 'api/v1/users/complete-profile',
    UPDATE_USER_PROFILE_API: 'api/v1/users/update-profile',
    LOGOUT_API: 'api/v1/auth/logout',
    FETCH_DEVICE_DATA_API: 'api/v1/devices/get-device-info',
    FETCH_DEVICES_OF_USER_API: 'api/v1/devices/get-all-devices-of-user',
    GET_ALL_DEVICES_OF_A_SITE_API: 'api/v1/devices/get-all-devices-of-site',
    ASSIGN_DEVICE_TO_USER_API: 'api/v1/users/assign-device',
    DEASSIGN_DEVICE_API: 'api/v1/users/unassign-device',
    GET_CONSUMERS_OF_SITE_API: 'api/v1/users/get-all-consumers-of-site/',
    INTIMATE_LATEST_FIRMWARE_VERSION_API: 'api/v1/firmwares/intimate-latest-firmware-version',
    FIRMWARE_VERSIONS_OF_SITE_API: 'api/v1/firmwares/get-all-firmware-versions-of-site/'
};
