import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useBackdrop } from "../context/BackdropContext";
import api from "../utils";
import useSnackbarAlert from "./useSnackbarAlert";
import { API_ENDPOINTS } from "../config/apiEndpoints";

const AEP_TO_FETCH_DEVICES_OF_USER = API_ENDPOINTS.FETCH_DEVICES_OF_USER_API;
const CONSOLE_ERROR_MESSAGE = "Error fetching user devices:";
const ERROR_ALERT_MESSAGE_TEXT = "Error fetching user devices. Please try again.";
const ALERT_SEVERITY = "error";

const useFetchDevicesOfUser = (userId) => {
    const accessToken = useSelector((state) => state.user.accessToken);
    const [devices, setDevices] = useState([]);
    const { showBackdrop, hideBackdrop } = useBackdrop();
    const { showSnackbar, SnackbarComponent } = useSnackbarAlert();

    useEffect(() => {
        const fetchDevices = async () => {
            try {
                showBackdrop();
                const response = await api.get(`${AEP_TO_FETCH_DEVICES_OF_USER}/${userId}`, {
                    headers: { Authorization: `Bearer ${accessToken}` },
                });
                if (response.status === 200) {
                    setDevices(response.data.data);
                }
            } catch (error) {
                showSnackbar(ERROR_ALERT_MESSAGE_TEXT, ALERT_SEVERITY);
                console.error(CONSOLE_ERROR_MESSAGE, error);
            } finally {
                hideBackdrop();
            }
        };

        // Call fetchDevices when the component mounts
        fetchDevices();
 // eslint-disable-next-line 
    }, [accessToken, userId]);

    return { devices, SnackbarComponent };
};

export default useFetchDevicesOfUser;
