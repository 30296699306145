import React from "react";
import { List, Paper, useMediaQuery } from "@mui/material";
import { AccountCircle, AppSettingsAlt, Build, ChangeCircle, Devices, DevicesOther, Group, Help, Logout, SystemUpdate } from "@mui/icons-material";
import { ROUTES } from "../../config";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SidebarItem from "./SidebarItem";
import styles from "./Sidebar.module.css";

const ROLE = {
  SITE_ADMIN: "site_admin",
  CONSUMER: "consumer",
};

const LIST_ITEM_TEXT = {
  ALL_DEVICES: "All Devices",
  YOUR_DEVICES: "Your Devices",
  PROFILE: "Profile",
  CONSUMERS: "Consumers",
  HELP: "Help",
  LOGOUT: "Logout",
  ROLE_MANAGEMENT: "Role Management",
  DEVICE_MANANEMENT: "Device Management",
  MAINTAINANCE: "Maintainance",
  INTIMATE_DEVICES: "Intimate Devices",
};

const LIST_COMPONENT_TYPE = {
  NAVIGATOR: "nav",
  DIV: "div",
};




const Sidebar = ({ isDrawer, handleLogout, setIsMobileMenuToggled }) => {
  const navigate = useNavigate();

  const isHelpSection = useMediaQuery("(max-width: 900px)");
  const role = useSelector((state) => state.user.role);


  const handleItemClick = (section) => {
    navigate(section);
    if (isDrawer) setIsMobileMenuToggled(false);
  };

  return (
    <Paper className={styles.sidebarContainer}>
      <List component={LIST_COMPONENT_TYPE.NAVIGATOR}>
        {/* {role === ROLE.CONSUMER && ( */}
          <SidebarItem icon={Devices} text={LIST_ITEM_TEXT.YOUR_DEVICES} onClick={() => handleItemClick(ROUTES.USER_DEVICES)} />
        {/* )} */}
        {role === ROLE.SITE_ADMIN && (
          <>
            <SidebarItem icon={DevicesOther} text={LIST_ITEM_TEXT.ALL_DEVICES} onClick={() => handleItemClick(ROUTES.DEVICES)} />
            <SidebarItem icon={Group} text={LIST_ITEM_TEXT.CONSUMERS} onClick={() => handleItemClick(ROUTES.CONSUMERS)} />
            <SidebarItem icon={ChangeCircle} text={LIST_ITEM_TEXT.ROLE_MANAGEMENT} onClick={() => handleItemClick(ROUTES.ROLE_MANAGEMENT)} />
           <SidebarItem icon={AppSettingsAlt} text={LIST_ITEM_TEXT.DEVICE_MANANEMENT} onClick={() => handleItemClick(ROUTES.DEVICE_MANANEMENT)} />
            <SidebarItem icon={Build} text={LIST_ITEM_TEXT.MAINTAINANCE} onClick={() => handleItemClick(ROUTES.MAINTAINANCE)} />
            <SidebarItem icon={SystemUpdate} text={LIST_ITEM_TEXT.INTIMATE_DEVICES} onClick={() => handleItemClick(ROUTES.INTIMATE_DEVICES)} />
          </>
        )}
        <SidebarItem icon={AccountCircle} text={LIST_ITEM_TEXT.PROFILE} onClick={() => handleItemClick(ROUTES.USER_PROFILE)} />
        {isHelpSection && (
          <SidebarItem icon={Help} text={LIST_ITEM_TEXT.HELP} onClick={() => handleItemClick(ROUTES.HELP)} />
        )}
        {isDrawer && (
          <SidebarItem icon={Logout} text={LIST_ITEM_TEXT.LOGOUT} onClick={handleLogout} />
        )}
      </List>
    </Paper>
  );
};

export default Sidebar;

