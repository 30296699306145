import { useState, useCallback } from 'react';
import api from '../utils';
import { API_ENDPOINTS } from '../config/apiEndpoints';

const AEP_TO_CHECK_USER_EXIST = API_ENDPOINTS.CHECK_USER_EXIST_API;

const EMPTY = '';
const ERROR_MESSAGE__FOR_CHECKING_REGISTRATION_STATUS = 'Error checking registration status.';

const useUserExist = () => {
  const [isChecking, setIsChecking] = useState(false);
  const [error, setError] = useState(EMPTY);

  const checkUserExist = useCallback(async ({ email = null, mobile_number = null }) => {
    setIsChecking(true);
    setError(EMPTY);

    try {
      const response = await api.post(AEP_TO_CHECK_USER_EXIST, { email, mobile_number });
      if (response.status === 200) {
        return response.data.message.user_exist; 
      }
    } catch (err) {
      setError(ERROR_MESSAGE__FOR_CHECKING_REGISTRATION_STATUS);
      return null;
    } finally {
      setIsChecking(false);
    }
  }, []);

  return { checkUserExist, isChecking, error };
};

export default useUserExist;
