import React, { useEffect, useMemo, useState } from 'react';
import { Box, Button, Typography, FormControl, Grid2, Paper, Tooltip, Fab, TextField, DialogTitle, Dialog, IconButton, DialogContent, DialogActions } from '@mui/material';
import api, { validateMobileNumber } from '../../utils';
import { AddressInput, MobileNumberInput, NameInput } from '../inputFeilds';
import { useDispatch, useSelector } from 'react-redux';
import { Close, Edit } from '@mui/icons-material';
import styles from './UserProfile.module.css';
import { useSnackbarAlert } from '../../hooks';
import { setLogout, setUser } from '../../state';
import { API_ENDPOINTS } from '../../config/apiEndpoints';

const AEP_FOR_UPDATE_USER_DETAILS = API_ENDPOINTS.UPDATE_USER_PROFILE_API;
const AEP_FOR_GET_USER_DETAILS = 'api/v1/users/get-user-profile-by-id/';

const TEXTFIELD_PROPS = {
  fullWidth: true,
  required: true,
  variant: 'outlined',
};

const MOBILE_INPUT_PROPS = {
  ...TEXTFIELD_PROPS,
  type: 'numeric',
  name: 'mobile',
};

const ADDRESS_INPUT_PROPS = {
  ...TEXTFIELD_PROPS,
  name: 'address',
  type: 'text',
};

const NAME_INPUT_PROPS = {
  ...TEXTFIELD_PROPS,
  name: 'name',
  type: 'text',
};

const UserProfile = ({ userId }) => {
  const user = useSelector((state) => state.user);
  const token = user.accessToken;
  const loginedRole = user.role;

  const dispatch = useDispatch();

  // State variables
  const [editMode, setEditMode] = useState(false);
  const [userData, setUserData] = useState({});
  const [openDialog, setOpenDialog] = useState(false);

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {

    setOpenDialog(false);
  };

  const { showSnackbar, SnackbarComponent } = useSnackbarAlert();

  // Individual fields state
  const [name, setName] = useState('');
  const [mobile, setMobile] = useState('');
  const [address, setAddress] = useState('');

  // Fetch user details by ID when the component mounts
  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await api.get(`${AEP_FOR_GET_USER_DETAILS}${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setUserData(response.data.data);

      } catch (error) {
        console.error('Error fetching user details:', error);
        showSnackbar('Error fetching user details', 'error');
      }
    };

    fetchUserDetails();
  }, [userId, token, showSnackbar]);

  // Update form fields when user data is fetched
  useEffect(() => {
    if (userData) {
      setName(userData.name || '');
      setMobile(userData.mobile_number || '');
      setAddress(userData.address || '');
    }
  }, [userData]);

  // Validation
  const isMobileValid = useMemo(() => validateMobileNumber(mobile), [mobile]);
  const isNameValid = useMemo(() => name?.length >= 4, [name]);
  const isAddressValid = useMemo(() => address?.length >= 10, [address]);
  const isFormValid = useMemo(() => isNameValid && isAddressValid && isMobileValid, [isNameValid, isAddressValid, isMobileValid]);

  // Handlers
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await api.post(AEP_FOR_UPDATE_USER_DETAILS, {
        name,
        email: userData.email,
        mobile_number: mobile,
        address
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        if (user.userId === response.data.data.id) dispatch(setUser(response.data.data));
        showSnackbar('User details updated successfully', 'success');
      } else {
        throw new Error('Failed to update user details');
      }
    } catch (error) {
      console.error('Error updating user details:', error);
      showSnackbar('Error updating user details', 'error');
    } finally {
      setEditMode(false);
    }
  };

  const handleDeleteAccount = async() => {
   try {
   await api.delete(`api/v1/users/delete-user-profile/${userData.id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    })
     dispatch(setLogout());
   } catch (error) {
    showSnackbar('Error deleting user', 'error');
    console.error('Error deleting user:', error);
   } 
   
  };

  const handleRoleChangeRequest = async () => {
    try {
      const requested_role = userData.current_role === "site_admin" ? "consumer" : userData.current_role === "consumer" ? "site_admin" : '';
      await api.post("api/v1/users/role-change-request", {
        user_id: userData.id,
        requested_role

      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      showSnackbar('Role change request sent successfully', 'success');
    } catch (error) {
      console.error('Error sending role change request:', error);
      showSnackbar('Error sending role change request', 'error');
    } finally {
      setOpenDialog(false);
    }
  }

  return (
    <Paper className={styles.userProfilePaper}>
      <Box className={styles.userProfileHeader}>
        <Typography variant="h5" className={styles.userProfileTitle}>
          User Profile
        </Typography>
        <Tooltip title="Edit Details" placement="bottom">
          <Fab
            color={editMode ? 'primary' : 'default'}
            aria-label="edit"
            className={styles.userProfileEditButton}
            onClick={() => setEditMode(!editMode)}
          >
            <Edit />
          </Fab>
        </Tooltip>
      </Box>

      <form onSubmit={handleSubmit} className={styles.userProfileForm}>
        <Grid2 container spacing={3}>
          <Grid2 size={{ xs: 12, sm: 6 }}>
            <FormControl fullWidth variant="outlined">
              <Typography variant="h6" sx={{ mb: 1 }}>
                Name
              </Typography>
              <NameInput
                {...NAME_INPUT_PROPS}
                name={name}
                setName={setName}
                isSubmitting={!editMode}
              />
            </FormControl>
          </Grid2>

          <Grid2 size={{ xs: 12, sm: 6 }}>
            <FormControl fullWidth variant="outlined">
              <Typography variant="h6" sx={{ mb: 1 }}>
                Email
              </Typography>
              <TextField
                id="email"
                name="email"
                value={userData.email}
                readOnly
              />
            </FormControl>
          </Grid2>

          <Grid2 size={{ xs: 12, sm: 6 }}>
            <FormControl fullWidth variant="outlined">
              <Typography variant="h6" sx={{ mb: 1 }}>
                Mobile Number
              </Typography>
              <MobileNumberInput
                {...MOBILE_INPUT_PROPS}
                mobile={mobile}
                setMobile={setMobile}
                isMobileValid={isMobileValid}
                isSubmitting={!editMode}
              />
            </FormControl>
          </Grid2>

          <Grid2 size={{ xs: 12, sm: 6 }}>
            <FormControl fullWidth variant="outlined">
              <Typography variant="h6" sx={{ mb: 1 }}>
                Address
              </Typography>
              <AddressInput
                {...ADDRESS_INPUT_PROPS}
                address={address}
                setAddress={setAddress}
                isSubmitting={!editMode}
              />
            </FormControl>
          </Grid2>

          <Grid2 size={{ xs: 12, sm: 6 }}>
            <FormControl fullWidth variant="outlined">
              <Typography variant="h6" sx={{ mb: 1 }}>
                Role
              </Typography>
              <TextField
                id="role"
                name="role"
                value={userData.current_role}
                readOnly
              />
            </FormControl>
          </Grid2>

          {(loginedRole === 'site_admin' || loginedRole === 'super_admin') && (
            <Grid2
              size={{ xs: 12, sm: 6 }}
              container
              alignItems="flex-end"
              justifyContent="space-around"
              sx={{ display: 'flex' }}
            >
              <Typography
                variant="body2"
                sx={{ marginTop: 'auto', textDecoration: 'underline', color: 'blue' }}
                onClick={handleClickOpen}
              >
                {userData.current_role === 'site_admin'
                  ? 'Request to become consumer'
                  : 'Request to become site admin'}
              </Typography>
            </Grid2>
          )}
        </Grid2>

        <Box className={styles.userProfileButtonContainer}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            className={styles.userProfileButton}
            disabled={!isFormValid || !editMode}
          >
            Save Changes
          </Button>

          <Button
            variant="outlined"
            color="error"
            onClick={handleDeleteAccount}
            className={styles.userProfileButton}
          >
            Delete Account
          </Button>
        </Box>
      </form>

      {/* Dialog Box */}
      <Dialog
        open={openDialog}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleCloseDialog();
          }
        }}
        aria-labelledby="request-dialog-title"
        fullWidth

        disableEscapeKeyDown // Prevent dialog from closing with Escape key
      >
        <DialogTitle id="request-dialog-title">
          <Typography variant="h6">Confirmation</Typography>
          <IconButton
            aria-label="close"
            onClick={handleCloseDialog}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>

        <DialogContent dividers>
          <Box sx={{ my: 2 }}>
            <Typography variant="body1">
              <strong>Email:</strong> {userData.email}
            </Typography>
            <Typography variant="body1">
              <strong>Current Role:</strong> {userData.current_role}
            </Typography>
            <Typography variant="body1">
              <strong>Request for:</strong>{' '}
              {userData.current_role === 'site_admin'
                ? 'Consumer Role'
                : 'Site Admin Role'}
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRoleChangeRequest}>Submit</Button>
        </DialogActions>
      </Dialog>

      {SnackbarComponent}
    </Paper>
  );
};

export default UserProfile;
