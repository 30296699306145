import React, { useEffect, useMemo, useState } from 'react';
import { Button, Typography, Grid2, Paper, Box, Select, MenuItem, InputLabel, FormControl, FormHelperText } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid'; 
import { useFetchDevicesOfSite, useFirmwareVersions, useIntimateFirmware } from '../../hooks';
import styles from './IntimateDevices.module.css';

const MODE = {
    MAINTAINANCE: "maintainance",
    OPERATIONAL: "operational"
};

const BUTTON = {
    SIZE_SMALL: "small",
    VARIANT_CONTAINED: "contained",
    VARIANT_OUTLINED: "outlined"
};

const TITLE = "Intimate Devices";

const SUBMIT_BUTTON_TEXT = "Intimate Devices";

const DATA_GRID_COLUMNS_FEILDS = {
    NAME: "name",
    MODE: "mode",
    VERSION: "version",
}

const DATA_GRID_COULUMS_HEADER_NAME = {
    NAME: "Device Name",
    MODE: "Mode",
    VERSION: "Current Version",
}

const INPUT_LABEL_ID = "version-select-input-label";
const SELECT_LABEL_ID = "version-select-label";
const VERSION_SELECT_LABEL="Select Version *";

const TEXT_NONE="None";
const TEXT_REQUIRED="Required";



const IntimateDevices = ({ siteId }) => {
    const [selectedVersion, setSelectedVersion] = useState('');
    const [isVersionError, setIsVersionError] = useState(false);

    const { devices, fetchDevices } = useFetchDevicesOfSite(siteId);
    const { intimateFirmwareVersion, SnackbarComponent } = useIntimateFirmware();
    const { versions } = useFirmwareVersions(siteId);

    useEffect(() => {
        fetchDevices();
    }, [fetchDevices]);

    const maintenanceDevices = useMemo(() => {
        return devices.filter(device => device.mode === MODE.MAINTAINANCE);
    }, [devices]);



    const handleSubmit = async () => {
        await intimateFirmwareVersion(selectedVersion, siteId);
    };

    const columns = [
        { field: DATA_GRID_COLUMNS_FEILDS.NAME, headerName: DATA_GRID_COULUMS_HEADER_NAME.NAME, flex: 1 },
        { field: DATA_GRID_COLUMNS_FEILDS.MODE, headerName: DATA_GRID_COULUMS_HEADER_NAME.MODE, flex: 1 },
        { field: DATA_GRID_COLUMNS_FEILDS.VERSION, headerName: DATA_GRID_COULUMS_HEADER_NAME.VERSION, flex: 1 },
    ];

    return (
        <Paper className={styles.maintainancePaper}>
            <Box className={styles.titleContainer}>
                <Typography className={styles.title}>
                    {TITLE}
                </Typography>
                <VersionSelect
                    versions={versions}
                    selectedVersion={selectedVersion}
                    setSelectedVersion={setSelectedVersion}
                    isVersionError={isVersionError}
                    setIsVersionError={setIsVersionError}
                />
            </Box>

            <DataGrid
                rows={maintenanceDevices}
                columns={columns}
                disableSelectionOnClick
                disableRowSelectionOnClick
                autoHeight
            />

            <Grid2 container  className={styles.submitButton}>
                <Button
                    variant={BUTTON.VARIANT_CONTAINED}
                    size={BUTTON.SIZE_SMALL}
                    onClick={handleSubmit}
                    disabled={!selectedVersion || maintenanceDevices.length === 0}
                >
                    {SUBMIT_BUTTON_TEXT}
                </Button>
            </Grid2>
            {SnackbarComponent}
        </Paper>
    );
};

export default IntimateDevices;


const VersionSelect = ({ versions, selectedVersion, setSelectedVersion, isVersionError, setIsVersionError }) => {
    return (
        <FormControl
            variant={BUTTON.VARIANT_OUTLINED}
            size={BUTTON.SIZE_SMALL}
            className={styles.versionSelect}
            error={isVersionError}
        >
            <InputLabel id={INPUT_LABEL_ID}>{VERSION_SELECT_LABEL}</InputLabel>
            <Select
                labelId={SELECT_LABEL_ID}
                value={selectedVersion}
                onChange={(e) => {
                    setSelectedVersion(e.target.value);
                    setIsVersionError(false);
                }}
                label={VERSION_SELECT_LABEL}
            >
                <MenuItem value=""><em>{TEXT_NONE}</em></MenuItem>
                {versions.map((version) => (
                    <MenuItem key={version} value={version}>{version}</MenuItem>
                ))}
            </Select>
            {!selectedVersion && <FormHelperText>{TEXT_REQUIRED}</FormHelperText>}
        </FormControl>
    );
};