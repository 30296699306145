// hooks/useRoleRequests.js

import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import api from '../utils';
import { setUser } from '../state';

const REQUESTS_API_URL = '/api/v1/users/get-pending-requests';
const APPROVED_API_URL = '/api/v1/users/get-processed-requests';
const APPROVE_REQUEST_API_URL = '/api/v1/users/approve-request';
const REJECT_REQUEST_API_URL = '/api/v1/users/reject-request';

const useRoleManagement = () => {
    const [requests, setRequests] = useState([]);
    const [processed, setProcessed] = useState([]);
    const user= useSelector((state) => state.user);
    const token = user.accessToken;
    const dispatch = useDispatch();

    const fetchRequests = async () => {
        try {
            const response = await api.get(REQUESTS_API_URL, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setRequests(response.data.data);
        } catch (error) {
            console.error('Error fetching requests:', error);
        }
    };

    const fetchProcessed = async () => {
        try {
            const response = await api.get(APPROVED_API_URL, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setProcessed(response.data.data);
        } catch (error) {
            console.error('Error fetching approved requests:', error);
        }
    };

    const handleApprove = async (rowData) => {
        const { id: user_id, currentRole: current_role, requestedRole: requested_role } = rowData;

        try {
            await api.post(APPROVE_REQUEST_API_URL, { user_id, current_role, requested_role }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            // Update requests and processed state
            if(user_id===user.userId){dispatch(setUser({ current_role: requested_role }));}
            setRequests((prevRequests) => prevRequests.filter((request) => request.id !== user_id));
            setProcessed((prevProcessed) => [...prevProcessed, rowData]);
        } catch (error) {
            console.error('Error approving user:', error);
        }
    };

    const handleReject = async (rowData) => {
        const { id: user_id, currentRole: current_role, requestedRole: requested_role } = rowData;

        try {
            await api.post(REJECT_REQUEST_API_URL, { user_id, current_role, requested_role }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            // Update requests state
            setRequests((prevRequests) => prevRequests.filter((request) => request.id !== user_id));
        } catch (error) {
            console.error('Error rejecting user:', error);
        }
    };

    useEffect(() => {
        fetchRequests();
        fetchProcessed();
        // eslint-disable-next-line
    }, [token]);

    return {
        requests,
        processed,
        handleApprove,
        handleReject,
    };
};

export default useRoleManagement;
