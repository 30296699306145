import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import api from '../utils';
import { setUser } from '../state';
import { ROUTES } from '../config';
import useMessage from './useMessage';
import { API_ENDPOINTS } from '../config/apiEndpoints';

// API Endpoints
const AEP_TO_COMPLETE_PROFILE = API_ENDPOINTS.COMPLETE_PROFILE_API;

// Navigation Routes

// Messages
const EMPTY = '';

const useCompleteProfile = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {message, setMessage} = useMessage();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector(state => state.user.accessToken);

  const submitProfileDetails = async ({ name, mobile_number, email, address, role, site_id }) => {
    setIsSubmitting(true);
    setMessage(EMPTY);
    try {
      const response = await api.post(
        AEP_TO_COMPLETE_PROFILE,
        {
          name,
          mobile_number,
          email,
          address,
          role,
          site_id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200 || response.status === 201) {
        dispatch(setUser({
          name: response.data.data.name,
          email: response.data.data.email,
          mobile_number: response.data.data.mobile_number,
          address: response.data.data.address,
          role: response.data.data.role,
          site_id: response.data.data.site_id,
          is_verified: true,
          is_profile_completed: true,
          access_token: token
        }));
        navigate(ROUTES.HOME);
      }
    } catch (error) {
      setMessage(error.response.data.message);
    } finally {
      setIsSubmitting(false);
    }
  };


  return { submitProfileDetails, isSubmitting, message };
};

export default useCompleteProfile;
