// RoleManagement.js

import React, { useState } from 'react';
import { Box, Tabs, Tab, Paper, Button, Chip } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';// Import the custom hook
import { useRoleManagement } from '../../hooks';
import styles from './RoleManagement.module.css';

const REQUESTS_TAB_LABEL = "Requests";
const APPROVED_REJECTED_TAB_LABEL = "Approved/Rejected";

const RoleManagement = () => {
    const [tab, setTab] = useState(0);
    const { requests, processed, handleApprove, handleReject } = useRoleManagement(); // Use the hook

    const handleChange = (event, newValue) => {
        setTab(newValue);
    };

    const requestColumns = [
        { field: 'name', headerName: 'Username', flex: 1 },
        { field: 'current_role', headerName: 'Current Role', flex: 1 },
        { field: 'requested_role', headerName: 'Requested Role', flex: 1 },
        {
            field: 'approve',
            headerName: 'Approve',
            flex: 1,
            renderCell: (params) => (
                <Button
                    variant="contained"
                    color="success"
                    onClick={() => handleApprove(params.row)}
                >
                    Approve
                </Button>
            ),
        },
        {
            field: 'reject',
            headerName: 'Reject',
            flex: 1,
            renderCell: (params) => (
                <Button
                    variant="contained"
                    color="error"
                    onClick={() => handleReject(params.row)}
                >
                    Reject
                </Button>
            ),
        },
    ];

    const approvedColumns = [
        { field: 'name', headerName: 'Username', flex: 1 },
        { field: 'current_role', headerName: 'Current Role', flex: 1 },
        { field: 'requested_role', headerName: 'Requested Role', flex: 1 },
        {
            field: 'status',
            headerName: 'Status',
            flex: 1,
            renderCell: (params) => {
                const statusColor = params.row.status === 'approved' ? 'success' : 'error';
                return (
                    <Chip
                        label={params.row.status.toUpperCase()}
                        color={statusColor}
                        variant="contained"
                        className={styles.statusChip}
                    />
                );
            },
        },
    ];

    return (
        <Paper className={styles.paper}>
            <Box className={styles.tabBox}>
                <Tabs value={tab} onChange={handleChange} aria-label="role management tabs">
                    <Tab label={REQUESTS_TAB_LABEL} />
                    <Tab label={APPROVED_REJECTED_TAB_LABEL} />
                </Tabs>
            </Box>
            <Box className={styles.dataGridBox}>
                {tab === 0 ? (
                    <DataGrid
                        rows={requests}
                        columns={requestColumns}
                        pageSize={5}
                        rowsPerPageOptions={[5]}
                        disableSelectionOnClick
                        disableRowSelectionOnClick
                        />
                    ) : (
                        <DataGrid
                        rows={processed}
                        columns={approvedColumns}
                        pageSize={5}
                        rowsPerPageOptions={[5]}
                        disableSelectionOnClick
                        disableRowSelectionOnClick
                    />
                )}
            </Box>
        </Paper>
    );
};

export default RoleManagement;
