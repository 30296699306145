// routes.js
export const ROUTES = {
  HOME: '/',
  LOGIN: '/login',
  REGISTER: '/register',
  ADD_DETAILS: '/add-details',
  USER_PROFILE: 'user/profile',
  DEVICES: 'user/site-admin/devices',
  CONSUMERS: 'user/site-admin/consumers',
  USER_DEVICES: 'user/devices',
  HELP: 'user/help',
  ROLE_MANAGEMENT: 'user/site-admin/role-management',
  DEVICE_MANANEMENT: 'user/site-admin/device-management',
  MAINTAINANCE: 'user/site-admin/maintainance',
  INTIMATE_DEVICES: 'user/site-admin/intimate-devices',
};

export const GRAFANA = {
  URL: "http://localhost:3001/d-solo/adyd62hov0vswe/new-dashboard3?orgId=1"
};
