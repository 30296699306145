import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Dialog, DialogContent, DialogTitle, IconButton, Paper, Typography } from '@mui/material';
import Dashboard from '../dashboard';
import styles from './DeviceUnderSite.module.css';
import { useFetchDevicesOfSite } from '../../hooks';
import { Close } from '@mui/icons-material';

const DIALOG_CLOSE_REASON = "backdropClick";
const TITLE = "Devices Under Site";
const DIALOG_TITLE_ID = "device-under-site-dialog-title";
const ICON_BUTTON_ARIA_LABEL = "Close";

const DATA_GRID_COLUMNS_FEILDS = {
  DEVICE_ID: "id",
  ASSIGNED_USER: "assigned_user",
  MODE: "mode",
}

const DATA_GRID_COULUMS_HEADER_NAME = {
  DEVICE_ID: "Device ID",
  ASSIGNED_USER: "Assigned User",
  MODE: "Mode",
}


const DevicesUnderSite = ({siteId}) => {
  const [openModal, setOpenModal] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const { devices, fetchDevices, SnackbarComponent } = useFetchDevicesOfSite(siteId);

  useEffect(() => {
    fetchDevices();
  }, [fetchDevices]);


  const columns = [
    { field: DATA_GRID_COLUMNS_FEILDS.DEVICE_ID, headerName: DATA_GRID_COULUMS_HEADER_NAME.DEVICE_ID, flex: 1 },
    { field: DATA_GRID_COLUMNS_FEILDS.ASSIGNED_USER, headerName: DATA_GRID_COULUMS_HEADER_NAME.ASSIGNED_USER, flex: 1 },
    { field: DATA_GRID_COLUMNS_FEILDS.MODE, headerName: DATA_GRID_COULUMS_HEADER_NAME.MODE, flex: 2 / 3 },
  ];


  const handleRowClick = (params) => {
    setSelectedDevice(params.row);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedDevice(null);
  };

  return (
    <Paper className={styles.devicePaper}>
      <Typography className={styles.title}>
        {TITLE}
      </Typography>
      <DataGrid
        rows={devices}
        columns={columns}
        pageSize={4}
        rowsPerPageOptions={[5, 6]}
        disableRowSelectionOnClick
        onRowClick={handleRowClick}
        autoHeight
      />
       <DeviceDialog
        open={openModal}
        onClose={handleCloseModal}
        selectedDevice={selectedDevice}
      />
      {SnackbarComponent}
    </Paper>
  );
};

export default DevicesUnderSite;

const DeviceDialog = ({ open, onClose, selectedDevice }) => {
  const handleClose = (event, reason) => {
    if (reason !== DIALOG_CLOSE_REASON) {
      onClose();
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} PaperProps={{ className: styles.dialogPaper }}>
      <DialogTitle id={DIALOG_TITLE_ID}>
        <IconButton
          aria-label={ICON_BUTTON_ARIA_LABEL}
          onClick={onClose}
          className={styles.dialogIconButton}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent className={styles.dialogContent}>
        {selectedDevice && <Dashboard deviceId={selectedDevice.id} />}
      </DialogContent>
    </Dialog>
  );
};
